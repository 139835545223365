var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-spot-widget',_vm._b({scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"properties",fn:function(){return [_vm._t("properties")]},proxy:true},{key:"overlay",fn:function(){return [_vm._t("overlay")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"values"},[(_vm.metrics.continuous.flowTemperature)?_c('div',{staticClass:"value"},[_c('domain-ui-metric-point',{attrs:{"point":_vm.metrics.continuous.flowTemperature,"spot":_vm.spot,"format-options":{ decimalsRound: 0 },"inline":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var point = ref.point;
var formattedValue = ref.formattedValue;
return [_c('div',{staticClass:"temperature"},[_vm._v(_vm._s(formattedValue))]),_c('domain-ui-metric',{staticClass:"place",attrs:{"name":point.name,"spot":_vm.spot},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var shortLabel = ref.shortLabel; if ( shortLabel === void 0 ) shortLabel = 'Heizung';
return [_vm._v(_vm._s(shortLabel))]}}],null,true)})]}}],null,false,4127404345)})],1):_vm._e(),(_vm.metrics.continuous.temperature)?_c('div',{staticClass:"value"},[_c('domain-ui-metric-point',{attrs:{"point":_vm.metrics.continuous.temperature,"spot":_vm.spot,"format-options":{ decimalsRound: 0 },"inline":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var point = ref.point;
var formattedValue = ref.formattedValue;
return [_c('div',{staticClass:"temperature"},[_vm._v(_vm._s(formattedValue))]),_c('domain-ui-metric',{staticClass:"place",attrs:{"name":point.name,"spot":_vm.spot},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var shortLabel = ref.shortLabel; if ( shortLabel === void 0 ) shortLabel = 'Raum';
return [_vm._v(_vm._s(shortLabel))]}}],null,true)})]}}],null,false,506201040)})],1):_vm._e()])]},proxy:true},{key:"footer",fn:function(){return [_c('core-spot-widget-counter-control',{attrs:{"metrics":_vm.metrics,"spot":_vm.spot,"role":_vm.role,"metric-name":"currentConsumption"}})]},proxy:true}],null,true)},'ui-spot-widget',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }